<template>
  <v-container id="user-login" fluid tag="section" fill-height>
    <v-row justify="center" align="center">
      <v-col cols="8" md="4" lg="3">
        <validation-observer ref="observer">
          <base-material-card>
            <template #heading>
              <div class="display-2 font-weight-light">
                {{ $t('login.dashboard-login') }}
              </div>
            </template>
            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('login.login')"
                    >
                      <v-text-field
                        ref="token"
                        v-model="token"
                        class="purple-input"
                        :label="$t('login.login')"
                        name="token"
                        auto-complete="username"
                        :error-messages="errors"
                        @keydown.enter="onTokenEnter"
                        @keydown.tab="onTokenTab"
                        @keydown.esc="startOver(100)"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="$t('login.password')"
                    >
                      <v-text-field
                        ref="password"
                        v-model="password"
                        class="purple-input"
                        :label="$t('login.password')"
                        type="password"
                        name="password"
                        auto-complete="current-password"
                        required
                        counter
                        :error-messages="errors"
                        @keydown.enter="submit"
                        @keydown.tab="onTokenTab"
                        @keydown.esc="startOver(100)"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn class="mr-0 accent" @click="submit">
                      {{ $t('login.login') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Users from '@/services/UserService.js';

export default {
  data: () => ({
    password: '',
    token: ''
  }),
  mounted() {
    this.focusToken();
  },
  methods: {
    async submit() {
      const result = await this.$refs.observer.validate();
      if (result) {
        try {
          const loginRes = await Users.login(this.token, this.password);
          // console.log(loginRes)
          // let decoded = jwt_decode(loginRes.access);
          // console.log(decoded);
          // const user = await Users.getUser(decoded.user_id)
          console.log('user:', loginRes.user);
          this.$store.commit('LOGIN', {
            accessToken: loginRes.access,
            exp: loginRes.exp,
            refreshToken: loginRes.refresh,
            login: this.token,
            user: loginRes.user,
            prefix: '/dashboard/'
          });

          this.$router.push({ name: 'orders' });
        } catch (err) {
          console.error(err);
          await this.$swal({
            title: this.$t('login.dashboard-login'),
            html: this.$t('login.failed'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          });
          this.startOver(400);
        }
      }
    },

    onTokenEnter() {
      if (this.token.length == 0) {
        return;
      }
      this.$nextTick(() => {
        this.focusPassword();
      });
    },
    onTokenTab() {
      if (this.token.length == 0) {
        return;
      }
    },
    startOver(delay) {
      setTimeout(() => {
        this.token = '';
        this.password = '';
        this.$refs.observer.reset();
        this.focusToken();
      }, delay);
    },
    focusToken() {
      this.$refs.token.focus();
    },
    focusPassword() {
      this.$refs.password.focus();
    }
  }
};
</script>
